import * as React from "react";
import { Helmet } from "react-helmet-async";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useSiteMetadata } from "../siteMetadata";

type SeoProps = {
  language: string;
  title: string;
  description: string;
  location: Location;
}

const Seo: React.FC<SeoProps> = ({
  language,
  title,
  description,
  location,
}) => {
  const siteMetadata = useSiteMetadata();
  const twitterMetaDataSite = React.useMemo(() => {
    switch (language) {
      case "ko":
        return "@CRKingdomKR";
      case "en":
        return "@CRKingdomEN";
      case "ja":
        return "@CRKingdomJP";
      default:
        return null;
    }
  }, [language]);
  return (
    <>
    <Helmet>
      <html lang={language} />
      <script>
        {`
          if (typeof window !== "undefined") {
            !function(w,d,s,t,a){if(w.amzn)return;w.amzn=a=function(){w.amzn.q.push([arguments,(new Date).getTime()])};a.q=[];a.version="0.0";s=d.createElement("script");s.src="https://c.amazon-adsystem.com/aat/amzn.js";s.id="amzn-pixel";s.async=true;t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);
            amzn("setRegion", "NA");
            amzn("addTag", "083ef772-5d8d-4366-bda2-538cbabeff06");
            amzn("trackEvent", "PageView");
          }
        `}
      </script>
    </Helmet>
    <GatsbySeo
      title={title}
      description={description}
      canonical={siteMetadata.siteUrl + location.pathname}
      openGraph={{
        type: "website",
        url: siteMetadata.siteUrl + location.pathname,
        title: title,
        description: description,
        images: [
          {
            url: `${siteMetadata.siteUrl}/og-${language}.jpg`,
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
        site_name: title,
      }}
      facebook={
        process.env.GATSBY_FACEBOOK_APP_ID
          ? {
            appId: process.env.GATSBY_FACEBOOK_APP_ID,
          }
          : undefined
      }
      twitter={
        twitterMetaDataSite
          ? {
            site: twitterMetaDataSite,
            handle: "@devsisters",
            cardType: "summary_large_image",
          }
          : undefined
      }
    />
    </>
  )
};

export default Seo;
