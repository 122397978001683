import * as React from "react";
import type { PageProps } from "gatsby";

import DefaultLayout from "./DefaultLayout";

type LayoutProps = Omit<
  PageProps<any, GatsbyTypes.SitePageContext>,
  "children"
> & {
  children: React.ReactNode;
};

const Layout = ({ children, ...props }: LayoutProps) => {
  if (
    ["/en/", "/ja/", "/ko/", "/zh-Hant/", "/th/", "/fr/", "/de/"].some((path) =>
      props.location.pathname.startsWith(path)
    )
  ) {
    return <DefaultLayout {...props}>{children}</DefaultLayout>;
  }
  return children;
};

export default Layout;
