import * as React from "react";
import type { PageProps } from "gatsby";
import { Global, css } from "@emotion/react";
import DefaultFontStyles from "@devsisters/gatsby-theme-emotion-webfonts/src/components/DefaultFontStyle";
import {
  defaultTheme,
  ThemeProvider,
} from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { carouselContext } from "gatsby-theme-cookierun-kingdom/src/carouselContext";

import Fonts from "../components/Fonts";
import {
  getTranslationText,
  getTranslationHTML,
  l10nContext,
} from "~/src/components/l10nContext";
import { useEasterEgg } from '~/src/useEasterEgg';
import Seo from "../components/Seo";

type DefaultLayoutProps = Omit<
  PageProps<GatsbyTypes.LocalizedIndexPageQuery, GatsbyTypes.SitePageContext>,
  "children"
>;

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  data,
  pageContext,
  children,
  location,
}) => {
  const [playCarousel, setPlayCarousel] = React.useState(true);

  type L10nContext = React.ContextType<typeof l10nContext>;
  const l10n = React.useMemo<L10nContext>(() => {
    if (!data?.__translation_messeages) {
      return null;
    }
    const { language, messages } = data.__translation_messeages;
    if (!(language && messages)) {
      return null;
    }
    return {
      language,
      messages,
      t: (key) => getTranslationText({ language, messages }, key),
      html: (key) => getTranslationHTML({ language, messages }, key),
    };
  }, [data]);
  const t = React.useCallback<NonNullable<L10nContext>["t"]>(
    (key) => {
      if (!l10n) {
        throw new Error("지역화 컨텍스트 주입하세요.");
      }
      return l10n.t(key);
    },
    [l10n]
  );
  const currentLanguage = l10n?.language;
  if (!pageContext.eventTargetId) {
    throw new Error(
      `${location.pathname} 페이지에 eventTargetId 컨텍스트가 없습니다`
    );
  }
  useEasterEgg(['Join us 👉 https://careers.devsisters.com/']);
  return (
    <carouselContext.Provider value={{ playCarousel, setPlayCarousel }}>
      <ThemeProvider theme={defaultTheme}>
        <l10nContext.Provider value={l10n}>
          {currentLanguage && (
            <>
              <Seo
                language={currentLanguage}
                title={t("IndexPage_OpenGraph_title")}
                description={t("IndexPage_OpenGraph_description")}
                location={location}
              />
              <Fonts
                notoSans={['en', 'de', 'fr'].includes(currentLanguage)}
                notoSansJp={currentLanguage === "ja"}
                notoSansKr={currentLanguage === "ko"}
                notoSansTc={currentLanguage === "zh-Hant"}
                notoSansTh={currentLanguage === "th"}
                cookieRun={currentLanguage === "en" || currentLanguage === "ko"}
                cafe24Danjunghae={
                  currentLanguage === "en" || currentLanguage === "ko"
                }
              />
            </>
          )}
          <Global
            styles={css({
              body: {
                margin: 0,
                backgroundColor: "rgb(255, 255, 255)",
              },
            })}
          />
          <DefaultFontStyles />
          {children}
        </l10nContext.Provider>
      </ThemeProvider>
    </carouselContext.Provider>
  );
};

export default DefaultLayout;
