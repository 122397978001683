module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"[쿠키런: 킹덤]","short_name":"[쿠키런: 킹덤]","description":"쿠키런 킹덤의 시민이 되어주세요.","start_url":"/","lang":"ko","background_color":"#161211","theme_color":"#161211","display":"minimal-ui","icon":"src/images/kingdom-app-icon.png","localize":[{"start_url":"/ko/","lang":"ko","name":"[쿠키런: 킹덤]","short_name":"[쿠키런: 킹덤]","description":"처음 ‘맛’나는 소셜 RPG"},{"start_url":"/en/","lang":"en","name":"[CookieRun: Kingdom]","short_name":"[CookieRun: Kingdom]","description":"Reclaim your dream CookieRun Kingdom!"},{"start_url":"/ja/","lang":"ja","name":"[クッキーラン：キングダム]","short_name":"[クッキーラン：キングダム]","description":"初めて“味”わうソーシャルRPG"},{"start_url":"/th/","lang":"th","name":"คุกกี้รัน: คิงดอม","short_name":"คุกกี้รัน: คิงดอม","description":"ครั้งแรกกับเกม RPG แสน ‘อร่อย’!"},{"start_url":"/zh-Hant/","lang":"zh-Hant","name":"《薑餅人王國》","short_name":"《薑餅人王國》","description":"薑餅人首款社群RPG大作"},{"start_url":"/de/","lang":"de","name":"[CookieRun: Kingdom]","short_name":"[CookieRun: Kingdom]","description":"Errichte das Cookie-Königreich deiner Träume!"},{"start_url":"/fr/","lang":"fr","name":"[CookieRun: Kingdom]","short_name":"[CookieRun: Kingdom]","description":"Retrouvez le Royaume Cookie de vos rêves !"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"77cceab913e8c52716967cdf734419f8"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/web-ck-sites/web-ck-sites/www.cookierun-kingdom.com/src/layouts/index.tsx"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[],"pluginConfig":{"head":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MJ728PL","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"zF5T2RmglXiGFPnD0Gf8QA","gtmPreview":"env-1","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"358464042874476"},
    }]
