import * as React from "react";
import { graphql } from "gatsby";

type L10NContext = {
  language: string;
  messages:
    | GatsbyTypes.LokaliseTranslationMessages_allTextMessagesFragment
    | GatsbyTypes.LokaliseTranslationMessages_allHTMLMessagesFragment;
  t: (
    key: keyof GatsbyTypes.LokaliseTranslationMessages_allTextMessagesFragment
  ) => string;
  html: (
    key: keyof GatsbyTypes.LokaliseTranslationMessages_allHTMLMessagesFragment
  ) => string;
};

export const l10nContext = React.createContext<L10NContext | null>(null);

export const useL10N = () => {
  const l10n = React.useContext(l10nContext);
  if (!l10n) {
    throw new Error(`
지역화 리소스를 찾을 수 없습니다. 혹시 페이지에 쿼리 추가하는거 깜빡하신거 아닙니까?

작업 중인 페이지에 다음과 같이 페이지 쿼리를 추가하세요.
const query = graphql\`
  query MyPage($translationId: String!) {
    ...TranslationMessages
  }
\`
    `);
  }
  return l10n;
};

export function getTranslationText(
  l10n: {
    language: string;
    messages: GatsbyTypes.LokaliseTranslationMessages_allTextMessagesFragment;
  },
  key: keyof GatsbyTypes.LokaliseTranslationMessages_allTextMessagesFragment
) {
  const message = l10n.messages[key];
  if (process.env.STRICT_L10N === "true" && !message?.text) {
    throw new Error(
      `번역 리소스를 찾을 수 없습니다. lang: ${l10n.language}, key: ${key}`
    );
  }
  return message?.text ?? "⚠️⚠️⚠️";
}

export function getTranslationHTML(
  l10n: {
    language: string;
    messages: GatsbyTypes.LokaliseTranslationMessages_allHTMLMessagesFragment;
  },
  key: keyof GatsbyTypes.LokaliseTranslationMessages_allHTMLMessagesFragment
) {
  const message = l10n.messages[key];
  if (process.env.STRICT_L10N === "true" && !message?.html) {
    throw new Error(
      `번역 리소스를 찾을 수 없습니다. lang: ${l10n.language}, key: ${key}`
    );
  }
  return message?.html ?? "⚠️⚠️⚠️";
}

export function useLanguage() {
  return useL10N().language;
}

export const useTranslation = () => {
  const l10n = useL10N();
  return {
    t: l10n.t,
    html: l10n.html,
  };
};

export const fragments = graphql`
  fragment TranslationMessages on Query {
    __translation_messeages: lokaliseTranslationMessages(
      id: { eq: $translationId }
    ) {
      language
      messages {
        ...LokaliseTranslationMessages_allTextMessages
        ...LokaliseTranslationMessages_allHTMLMessages
      }
    }
  }
`;
