import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { parseLink as _parseLink } from "gatsby-theme-cookierun-kingdom/src/link";

export function useSiteMetadata() {
  const data = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);
  if (!data.site?.siteMetadata) {
    throw new Error("siteMetadata should be exist");
  }

  return data.site.siteMetadata;
}

export function useLinkUtils() {
  const { siteUrl } = useSiteMetadata();
  if (!siteUrl) {
    throw new Error("siteUrl should be exist");
  }
  return React.useMemo(() => {
    const baseUrl = new URL(siteUrl);
    const parseLink = (link: string) => _parseLink(link, baseUrl.origin);
    return {
      baseUrl,
      parseLink,
    };
  }, [siteUrl]);
}

export function useLink(link: string) {
  const linkUtils = useLinkUtils();
  return React.useMemo(() => linkUtils.parseLink(link), [linkUtils, link]);
}
