import * as React from "react";
import NotoSansFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/en/NotoSans";
import NotoSansJpFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/ja/NotoSansJP";
import NotoSansTcFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/zh-Hant/NotoSansTC";
import NotoSansKrFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/ko/NotoSansKR";
import NotoSansThFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/th/NotoSansThai";
import Cafe24DanjunghaeFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/ko/Cafe24Danjunghae";
import CookieRunFont from "@devsisters/gatsby-theme-emotion-webfonts/src/components/ko/CookieRun";

type FontsProps = {
  notoSans?: boolean;
  notoSansJp?: boolean;
  notoSansTc?: boolean;
  notoSansKr?: boolean;
  notoSansTh?: boolean;
  cookieRun?: boolean;
  cafe24Danjunghae?: boolean;
};

const Fonts: React.FC<FontsProps> = ({
  notoSans = false,
  notoSansJp = false,
  notoSansTc = false,
  notoSansKr = false,
  notoSansTh = false,
  cookieRun = false,
  cafe24Danjunghae = false,
}) => (
  <>
    {notoSans && <NotoSansFont />}
    {notoSansJp && <NotoSansJpFont />}
    {notoSansKr && <NotoSansKrFont />}
    {notoSansTc && <NotoSansTcFont />}
    {notoSansTh && <NotoSansThFont />}
    {cookieRun && <CookieRunFont />}
    {cafe24Danjunghae && <Cafe24DanjunghaeFont />}
  </>
);

export default React.memo(Fonts);
